<template>
  <div>
    <!-- Filters -->
    <Filters
      class="user-select-none"
      :warning-text="warningTextFilter"
      :status-options="statusList"
      :status-filter.sync="statusFilter"
      :category-filter.sync="categoryFilter"
      :user-options="userListPKH"
      :user-filter.sync="userFilter"
      :department-options="departmentOptions"
      :department-filter.sync="departmentFilter"
      :search-query.sync="searchQuery"
      :range-date-filter.sync="rangeDateFilter"
      :no-auto-compare-time="noAutoCompareTime"
      :has-plan-filter.sync="hasPlanFilter"
      :is-assigned-filter.sync="isAssignedFilter"
      :has-collected-filter.sync="hasCollectedFilter"
      @clear-filter="clearFilter"
    />
    <b-card
      no-body
      class="mb-0 pb-0"
    >
      <div
        class="py-1 px-2 d-flex justify-content-between"
      >
        <div v-html="resultTitle" />
      </div>
      <b-table
        ref="refContractsListTable"
        class="position-relative custom-head thead-no-wrap line-height-1 font-small-3"
        responsive
        hover
        primary-key="id"
        show-empty
        small
        empty-text="Không có kết quả nào"
        :items="contracts"
        :fields="tableColumns"
        @row-clicked="rowClicked"
      >

        <!-- Column: Hồ sơ -->
        <template #cell(ho-so)="data">
          <b-media
            vertical-align="center"
            :class="`border-table-left-n-bg-by-status-${data.item.status}`"
          >
            <div class="text-uppercase text-nowrap text-primary font-small-4 mb-25">
              {{ data.item.document_code }}
            </div>
            <div
              v-if="data.item.status"
              class="font-small-4"
            >
              <b-badge
                :class="'bg-' + data.item.status"
                class="mb-25"
              >
                {{ $status.getName(data.item.status) }}
              </b-badge>
            </div>
            <div
              v-if="data.item.contract_code"
              class="text-uppercase text-nowrap text-contract_signed font-small-4 mb-25"
            >
              {{ data.item.contract_code }}
            </div>
          </b-media>
        </template>

        <!-- Column: Nhân viên PKH -->
        <template #cell(nv-pkh)="data">
          <b-media
            v-if="data.item.creator"
            vertical-align="center"
          >
            <div class="text-nowrap text-primary font-small-4 mb-50">
              {{ data.item.creator.fullname }}
            </div>
            <div class="line-height-20px">
              <div>{{ formatDate(data.item.created_at) }}</div>
            </div>
          </b-media>
        </template>

        <!-- Column: Phòng ban -->
        <template #cell(phong-ban)="data">
          <b-media
            v-if="data.item.department"
            vertical-align="center"
          >
            <div class="text-nowrap text-primary font-small-4 mb-50">
              {{ data.item.department.name }}
            </div>
            <div
              v-if="data.item.is_has_plan"
              class="d-flex"
            >
              <b-badge
                variant="light-primary"
                class="d-flex align-items-center"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="text-primary"
                />
                <span>Kế hoạch</span>
              </b-badge>
            </div>
            <div
              v-else
              class="line-height-20px"
            >
              <br>
            </div>
          </b-media>
        </template>

        <!-- Column: Khách hàng -->
        <template #cell(khach-hang)="data">
          <b-media vertical-align="center">
            <div class="d-flex">
              <span class="text-primary font-small-3 mb-50 font-weight-bolder">{{ data.item.customer.name }}</span>
            </div>
            <div class="d-flex">
              <feather-icon
                icon="MapPinIcon"
                size="14"
              />
              <span class="mb-50 pl-50">{{ data.item.customer.address }}</span>
            </div>
            <div class="d-flex align-items-end justify-content-between">
              <div>
                <feather-icon
                  icon="PhoneIcon"
                  size="14"
                />
                <span
                  v-if="data.item.customer.phone"
                  class="pl-50"
                >{{ data.item.customer.phone }}</span>
              </div>
              <b-badge
                v-if="data.item.customer.category"
                class="font-small-1 ml-25"
                :class="'bg-light-' + data.item.customer.category + ' text-' + data.item.customer.category"
              >
                {{ $customerCategory.getName(data.item.customer.category) }}
              </b-badge>
            </div>
          </b-media>
        </template>

        <!-- Column: Khách nợ -->
        <template #cell(khach-no)="data">
          <b-media vertical-align="center">
            <div class="d-flex">
              <span class="text-primary font-small-3 mb-50 font-weight-bolder">{{ data.item.debtor.name }}</span>
            </div>
            <div class="d-flex">
              <feather-icon
                icon="MapPinIcon"
                size="14"
              />
              <span class="mb-50 pl-50">{{ data.item.debtor.address }}</span>
            </div>
            <div
              class="d-flex align-items-end justify-content-between"
            >
              <div>
                <feather-icon
                  icon="PhoneIcon"
                  size="14"
                />
                <span
                  v-if="data.item.debtor.phone"
                  class="pl-50"
                >{{ data.item.debtor.phone }}</span>
              </div>
              <b-badge
                v-if="data.item.debtor.category"
                class="font-small-1 ml-25"
                :class="'bg-light-' + data.item.debtor.category + ' text-' + data.item.debtor.category"
              >
                {{ $customerCategory.getName(data.item.debtor.category) }}
              </b-badge>
            </div>
          </b-media>
        </template>
        <!-- Column: Giá trị -->
        <template #cell(gia-tri)="data">
          <b-media
            vertical-align="center"
            class="text-nowrap"
          >
            <div>
              <div class="d-flex justify-content-between mb-25">
                <span class="">Nợ gốc: &nbsp;</span>
                <span class="text-primary">{{ formatNumber(data.item.original_amount) }} đ</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="">Giá mua:</span>
                <span class="text-primary">{{ formatNumber(data.item.fee_to_buy_original) }} %</span>
              </div>
            </div>
            <hr class="mt-25 mb-50">
            <div>
              <div class="d-flex justify-content-between mb-25">
                <span class="">Nợ lãi: &nbsp;</span>
                <span class="text-primary">{{ formatNumber(data.item.interest_amount) }} đ</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="">Giá mua:</span>
                <span class="text-primary">{{ formatNumber(data.item.fee_to_buy_interest) }} %</span>
              </div>
            </div>
          </b-media>
        </template>
      </b-table>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContracts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BMedia, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import demartmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import http from '@/global/http'
import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import useContractsList from './useContractsList'
import Filters from './Filters.vue'

export default {
  components: {
    FixedRightButton,
    Filters,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    actionsButton() {
      return [
        {
          label: 'Xuất excel',
          icon: 'DownloadIcon',
          value: 'export-contract-list',
        },
      ]
    },
  },
  watch: {
    filterChange(n) {
      this.handleSearch()
    },
  },
  created() {
    this.initFilter()
    this.initData()
  },
  methods: {
    handleActionsButton(btn) {
      if (btn.value === 'export-contract-list') {
        this.exportContracts()
      }
    },
    initData() {
      this.statusList = this.$status.list
      this.filterContracts()
      this.fetchPublicUsers()
      this.fetchDepartments()
    },
    initFilter() {
      const filter = JSON.parse(this.$route.query.filter || '{}')
      this.statusFilter = filter.statusFilter || ''
      this.categoryFilter = filter.categoryFilter || ''
      this.hasPlanFilter = filter.hasPlanFilter || ''
      this.isAssignedFilter = filter.isAssignedFilter || ''
      this.userFilter = (filter.userFilter || '').toString()
      this.hasCollectedFilter = filter.hasCollectedFilter || ''
      this.departmentFilter = (filter.departmentFilter || '').toString()
      this.rangeDateFilter = filter.rangeDateFilter || ''
      this.noAutoCompareTime = filter.noAutoCompareTime || false
      this.searchQuery = filter.searchQuery || ''
      this.totalContracts = filter.totalContracts || 0
      this.currentPage = filter.currentPage || 1
      this.perPage = filter.perPage || 10
      this.typeDateFilter = filter.typeDateFilter || ''
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify({
            statusFilter: this.statusFilter,
            hasPlanFilter: this.hasPlanFilter,
            isAssignedFilter: this.isAssignedFilter,
            categoryFilter: this.categoryFilter,
            userFilter: this.userFilter,
            departmentFilter: this.departmentFilter,
            hasCollectedFilter: this.hasCollectedFilter,
            rangeDateFilter: this.rangeDateFilter,
            searchQuery: this.searchQuery,
            totalContracts: this.totalContracts,
            currentPage: this.currentPage,
            perPage: this.perPage,
            time: new Date().getTime(),
            typeDateFilter: this.typeDateFilter,
            noAutoCompareTime: this.noAutoCompareTime,
          } || ''),
        },
      })
    },
    exportContracts() {
      this.getLinkExportContracts()
        .then(res => {
          const link = res.data
          this.openLinkInNewTab(link)
        })
    },
    rowClicked(row) {
      this.$router.push({
        name: 'contracts-detail',
        params: { id: row.id },
      })
    },
    fetchPublicUsers() {
      this.$action(http.api(store.dispatch('user/fetchPublicUsers'), res => {
        this.userList = res.data
      }))
    },
    fetchDepartments() {
      this.$action(http.api(store.dispatch('department/fetchDepartments'), res => {
        this.departmentList = res.data
      }))
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract'
    const USER_STORE_MODULE_NAME = 'user'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, demartmentStoreModule)
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      ...useContractsList(),
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}
</style>
