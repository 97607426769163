<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc
      </h5>
      <feather-icon
        v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
        icon="XSquareIcon"
        size="18"
        class="cursor-pointer text-active ml-2"
        @click="clearFilter"
      />
    </b-card-header>
    <b-card-body class="pb-1">
      <b-row class="mb-1">
        <b-col
          cols="12"
          md="4"
          :lg="showAllFilters ? 3 : 4"
          class="mb-md-0 mb-50"
        >
          <label>Trạng thái</label>
          <v-select
            class="w-100 select-size-sm v-select-hiden-autocomplete"
            label="label"
            :value="statusFilter"
            multiple
            :options="statusOptions"
            :clearable="true"
            :reduce="val => '' + val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          v-if="showAllFilters"
          cols="12"
          md="4"
          lg="3"
          class="mb-md-0 mb-50 pl-md-0"
        >
          <label>Nhân viên PKH</label>
          <v-select
            class="w-100 select-size-sm"
            label="fullname"
            :value="userFilter"
            :options="userOptions"
            :clearable="true"
            :reduce="val => '' + val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
        <b-col
          v-if="showAllFilters"
          cols="12"
          md="4"
          lg="2"
          class="mb-md-0 mb-50 pl-md-0"
        >
          <label>Đội nghiệp vụ</label>
          <v-select
            class="w-100 select-size-sm"
            label="name"
            :value="departmentFilter"
            :options="departmentOptions"
            :clearable="true"
            :reduce="val => '' + val.id"
            @input="(val) => $emit('update:departmentFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          :lg="showAllFilters ? 1 : 2"
          class="mb-md-0 mb-50 pl-md-0"
        >
          <label>Tháng</label>
          <v-select
            v-model="monthFilter"
            class="w-100 select-size-sm v-select-hiden-autocomplete"
            label="name"
            :options="$monthOptions"
            :clearable="true"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          :lg="showAllFilters ? 1 : 2"
          class="mb-md-0 mb-50 pl-md-0"
        >
          <label>Năm</label>
          <v-select
            v-model="yearFilter"
            class="w-100 select-size-sm v-select-hiden-autocomplete"
            label="name"
            :options="$yearOptions"
            :clearable="true"
          />
          <!--                    <flat-pickr-->
          <!--                      size="sm"-->
          <!--                      :value="rangeDateFilter"-->
          <!--                      class="form-control"-->
          <!--                      :clearable="true"-->
          <!--                      :config="{altFormat: 'd-m-Y', altInput: true, mode: 'range', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"-->
          <!--                      @input="val => $emit('update:rangeDateFilter', val)"-->
          <!--                    />-->
        </b-col>
        <b-col
          cols="12"
          md="6"
          :lg="showAllFilters ? 2 : 4"
          class="mb-md-0 mb-50 pl-md-0"
        >
          <label for="form-search-query">Nội dung</label>
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="form-search-query"
                ref="search-query"
                v-model="search"
                :value="searchQuery"
                :clearable="true"
                size="sm"
                type="search"
                placeholder="Hồ sơ, tên..."
                @keydown.enter.prevent="e => $emit('update:searchQuery', e.target.value)"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer"
                  @click="$emit('update:searchQuery', $refs['search-query'].$el.value)"
                />
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="showAllFilters"

          cols="12"
          md="3"
        >
          <label>Nhóm khách hàng</label>
          <v-select
            class="w-100 select-size-sm v-select-hiden-autocomplete"
            label="label"
            :value="categoryFilter"
            :options="[{label: 'Công ty', value: 'company'}, {label: 'Cá nhân', value: 'personal'}]"
            :clearable="true"
            :reduce="val => val.value"
            @input="(val) => $emit('update:categoryFilter', val)"
          />
        </b-col>
        <b-col
          v-if="showAllFilters"
          cols="12"
          md="3"
          class="d-flex align-items-end"
        >
          <b-form-group class="mb-0 pt-1">
            <div class="d-flex ga-4">
              <b-form-checkbox
                :checked="filterHasAssigned"
                class="checkbox--like-radio"
                @change="handleCheckHasAssigned"
              >
                Đã giao đội
              </b-form-checkbox>
              <b-form-checkbox
                :checked="filterHasAssigned === false"
                class="checkbox--like-radio"
                @change="handleCheckNoAssigned"
              >
                Chưa giao đội
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
          class="mb-md-0 mb-50 pl-md-0 d-flex align-items-end"
        >
          <b-form-group class="mb-0 pt-1">
            <div class="d-flex ga-4">
              <b-form-checkbox
                :checked="filterHasPlan"
                class="checkbox--like-radio"
                @change="handleCheckHasPlan"
              >
                Có kế hoạch
              </b-form-checkbox>
              <b-form-checkbox
                :checked="filterHasPlan === false"
                class="checkbox--like-radio"
                @change="handleCheckNoPlan"
              >
                Chưa có kế hoạch
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
          class="mb-md-0 mb-50 pl-md-0 d-flex align-items-end"
        >
          <b-form-group class="mb-0 pt-1">
            <div class="d-flex ga-4">
              <b-form-checkbox
                :checked="filterHasCollected"
                class="checkbox--like-radio"
                @change="handleCheckHasCollected"
              >
                Đã thu được
              </b-form-checkbox>
              <b-form-checkbox
                :checked="filterHasCollected === false"
                class="checkbox--like-radio"
                @change="handleCheckNoCollected"
              >
                Chưa thu được
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-warning mt-2"
        >
          {{ warningText }}
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import ability from '@/libs/acl/ability'
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBTooltip,
  BFormGroup, BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    departmentFilter: {
      type: [String, null],
      default: null,
    },
    warningText: {
      type: String,
      default: null,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
    userFilter: {
      type: [String, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: undefined,
      default: null,
    },
    categoryFilter: {
      type: String,
      default: '',
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    searchQuery: {
      type: [String, null],
      default: null,
    },
    rangeDateFilter: {
      type: String,
      default: null,
      require: true,
    },
    noAutoCompareTime: {
      type: Boolean,
      default: false,
    },
    hasPlanFilter: {
      type: String,
      default: '',
    },
    isAssignedFilter: {
      type: String,
      default: '',
    },
    hasCollectedFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countToRefresh: 1,
      search: '',
      yearFilter: '',
      monthFilter: '',
      hasPlan: '',
      filterHasPlan: null,
      hasAssigned: '',
      filterHasAssigned: '',
      hasCollected: '',
      filterHasCollected: null,
    }
  },
  computed: {
    showAllFilters() {
      return ability().can('view-all-filters', 'Contract')
    },
  },
  watch: {
    yearFilter() {
      this.updateRangeFilter()
    },
    monthFilter() {
      if (!this.yearFilter) return
      this.updateRangeFilter()
    },
    rangeDateFilter(n) {
      if (!n) {
        this.yearFilter = ''
        this.monthFilter = ''
      }
    },
    search(n, o) {
      if (!n && o.length > 2) {
        this.$emit('update:searchQuery', '')
      }
    },
    hasPlanFilter: {
      immediate: true,
      handler(n) {
        this.hasPlan = n
      },
    },
    isAssignedFilter: {
      immediate: true,
      handler(n) {
        this.isAssigned = n
      },
    },
    hasCollectedFilter: {
      immediate: true,
      handler(n) {
        console.log('hasCollectedFilter', n)
        this.hasCollected = n
        // eslint-disable-next-line no-nested-ternary
        this.filterHasCollected = n === 'true' ? true : n === 'false' ? false : null
      },
    },
  },
  mounted() {
    if (this.noAutoCompareTime) return
    const [from, to] = this.rangeDateFilter ? this.rangeDateFilter.split(' to ') : ['', '']
    const yearFrom = from.split('-')[0] || ''
    const monthFrom = from.split('-')[1] || ''
    // const yearTo = to.split('-')[0] || ''
    const monthTo = to.split('-')[1] || ''
    this.yearFilter = yearFrom
    this.monthFilter = monthFrom === monthTo ? monthFrom : ''
  },
  methods: {
    clearFilter() {
      this.countToRefresh += 1
      this.monthFilter = ''
      this.yearFilter = ''
      this.$emit('clear-filter')
    },
    updateRangeFilter() {
      const m = this.monthFilter
      const y = this.yearFilter
      if (!y) {
        this.$emit('update:rangeDateFilter', ' to ')
      } else if (!m) {
        this.$emit('update:rangeDateFilter', `${y}-01-01 to ${y}-12-31`)
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.$emit('update:rangeDateFilter', `${y}-${m}-01 to ${y}-${m}-${endDate}`)
      }
    },
    handleCheckHasCollected() {
      if (this.filterHasCollected === null || this.filterHasCollected === false) {
        this.$emit('update:hasCollectedFilter', 'true')
        this.filterHasCollected = true
        return
      }
      this.$emit('update:hasCollectedFilter', '')
      this.filterHasCollected = null
    },
    handleCheckNoCollected() {
      if (this.filterHasCollected === null || this.filterHasCollected === true) {
        this.$emit('update:hasCollectedFilter', 'false')
        this.filterHasCollected = false
        return
      }
      this.$emit('update:hasCollectedFilter', '')
      this.filterHasCollected = null
    },
    handleCheckHasPlan() {
      if (this.filterHasPlan === null || this.filterHasPlan === false) {
        this.$emit('update:hasPlanFilter', 'true')
        this.filterHasPlan = true
        return
      }
      this.$emit('update:hasPlanFilter', '')
      this.filterHasPlan = null
    },
    handleCheckNoPlan() {
      if (this.filterHasPlan === null || this.filterHasPlan === true) {
        this.$emit('update:hasPlanFilter', 'false')
        this.filterHasPlan = false
        return
      }
      this.$emit('update:hasPlanFilter', '')
      this.filterHasPlan = null
    },
    handleCheckHasAssigned() {
      if (this.filterHasAssigned === null || this.filterHasAssigned === false) {
        this.$emit('update:isAssignedFilter', 'true')
        this.filterHasAssigned = true
        return
      }
      this.$emit('update:isAssignedFilter', '')
      this.filterHasAssigned = null
    },
    handleCheckNoAssigned() {
      if (this.filterHasAssigned === null || this.filterHasAssigned === true) {
        this.$emit('update:isAssignedFilter', 'false')
        this.filterHasAssigned = false
        return
      }
      this.$emit('update:isAssignedFilter', '')
      this.filterHasAssigned = null
    },
  },
}
</script>

<style lang="scss">
</style>
